/*----- index layout -----*/

#containar {
    margin-bottom: 50px;
  }
  
  .top--topics {
    padding: 0 0 80px;
    background-color: #efefef;
  }
  
  .neg-box {
    margin-top: -55px;
  }
  
  /* tabbox */
  
  #tabMenu {
    ul {
      width: 100%;
    }
  
    li {
      float: left;
      width: 20%;
  
      a {
        display: block;
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-right: #ccc 1px solid;
        background-color: #24a6e3;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
  
      &:last-child a {
        border-right: none;
      }
  
      a.active {
        background-color: #fff;
        color: #222;
      }
    }
  }
  
  #tabBox0, #tabBox1, #tabBox2, #tabBox3, #tabBox4 {
    width: 100%;
    padding: 35px 25px 20px;
    min-height: 300px;
    background-color: #fff;
  }
  
  #tabBox1, #tabBox2, #tabBox3, #tabBox4 {
    display: none;
  }
  
  .top--topics dl {
    display: table;
    width: 100%;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
    margin-bottom: 25px;
  
    dt, dd {
      padding: 0 10px;
    }
  
    dt {
      display: table-cell;
      width: 30%;
      font-weight: 500;
  
      span {
        display: inline-block;
        width: 140px;
        padding: 4px 0;
        margin-right: 20px;
        color: #fff;
        text-align: center;
      }
    }
  }
  
  span {
    &.news {
      background-color: #24a6e3;
    }
  
    &.consultation {
      background-color: #fe7e00;
    }
  
    &.important {
      background-color: #fe1800;
    }
  
    &.seminar {
      background-color: #4a7b06;
    }
  }
  
  .top--topics dl {
    dt time {
      display: inline-block;
    }
  
    dd a {
      color: #444;
      text-decoration: underline;
    }
  }
  
  /* tabbox END */
  
  .top--worry {
    background: #efefef url(../images/top/sec_bg.jpg) no-repeat left top;
    background-size: 100% auto;
    padding: 80px 0 130px;
  
    h2 {
      color: #fff;
    }
  
    figure {
      width: 211px;
      margin: auto;
      position: relative;
    }
  
    figcaption {
      position: absolute;
      background-color: #fff;
      padding: 30px 15px 0;
      width: 100%;
      min-height: 125px;
      top: 185px;
      z-index: 2;
      box-shadow: -5px 5px 0px rgba(0, 0, 0, 0.2);
  
      .pos_ttl {
        width: 94%;
        margin: -45px auto 10px;
        padding: 4px 0;
        text-align: center;
        font-size: 14px;
        color: #fff;
      }
    }
  
    .blue figcaption .pos_ttl {
      background-color: #2261d5;
    }
  
    .red figcaption .pos_ttl {
      background-color: #ec3301;
    }
  
    .orenge figcaption .pos_ttl {
      background-color: #ef880c;
    }
  
    .green figcaption .pos_ttl {
      background-color: #1ca91f;
    }
  
    figcaption .marker {
      font-weight: 600;
      font-size: 18px;
  
      em {
        font-size: 1.2em;
        font-style: normal;
      }
    }
  
    .blue figcaption .marker {
      color: #2261d5;
    }
  
    .red figcaption .marker {
      color: #ec3301;
    }
  
    .orenge figcaption .marker {
      color: #ef880c;
    }
  
    .green figcaption .marker {
      color: #1ca91f;
    }
  }
  
  /*.top--worry a:hover img{opacity: 1;}*/
  
  .top--case {
    padding: 50px 0;
    background-color: #efefef;
  }
  
  .link_box {
    position: relative;
  
    a:hover {
      background-color: #fff;
      filter: alpha(opacity = 30);
      -moz-opacity: 0.3;
      opacity: 0.3;
    }
  }
  
  .top--case {
    div.row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
    }
  
    .col-sm-3 {
      padding: 10px 20px;
      background-color: #fff;
      margin: 1%;
      width: 23%;
  
      p {
        &.num {
          color: #1d97d0;
          font-weight: 600;
        }
  
        margin: 0.5em 0;
      }
  
      dl {
        margin-bottom: 5px;
  
        dt, dd {
          display: table-cell;
        }
      }
  
      h3 {
        font-size: 16px;
        font-weight: 600;
        padding-top: 1em;
        border-top: 1px solid #ddd;
      }
    }
  }
  
  .top--advantage {
    padding: 6em 0;
  
    .row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
    }
  
    .col-sm-4 {
      width: 31.333%;
      border: 4px solid #24a6e3;
      padding: 1%;
      box-sizing: border-box;
      text-align: center;
  
      &.center-col {
        margin: 0 4%;
      }
  
      .num span {
        display: inline-block;
        padding: 5px 15px;
        background: #24a6e3;
        color: #fff;
        font-size: 20px;
      }
  
      h3 {
        font-weight: 600;
        margin-top: 35px;
        font-size: 30px;
  
        &.sm {
          font-size: 24px;
          margin-top: 15px;
        }
      }
    }
  }
  
  .top--contact {
    padding: 0 0 4em;
  
    .row {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
    }
  
    .col-sm-4 {
      width: 31.333%;
      background-color: #e5e5e5;
      padding: 2% 0 2% 3%;
      box-sizing: border-box;
  
      &.center-col {
        margin: 0 4%;
      }
  
      h3 {
        margin: 0;
  
        span {
          display: inline-block;
          padding: 5px 30px;
          text-align: center;
          background-color: #24a6e3;
          color: #fff;
          font-size: 16px;
        }
      }
  
      p {
        font-weight: 600;
        margin: 10px 0;
  
        &.tel-txt {
          font-size: 30px;
  
          em {
            font-style: normal;
            font-size: 0.8em;
          }
        }
      }
    }
  }
  
  .top--flow {
    padding: 4em 0;
    background-color: #efefef;
  }
  
  .message {
    padding: 4em 0;
  
    h2.h2__ttl--center .bdr {
      padding-bottom: 5px;
      border-bottom: 3px solid #24a6e3;
    }
  
    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.8;
    }
  
    .img-wrap {
      max-width: 285px;
      width: 40%;
    }
  }
  
  .foot--bnr {
    max-width: 1180px;
    margin: auto;
  
    .col-sm-6 {
      padding: 7px;
    }
  }
  
  .foot-area {
    max-width: 1180px;
    width: 96%;
    margin: 4em auto 1em;
  
    .img-wrap {
      max-width: 490px;
    }
  
    .inner_right {
      flex: 1;
      margin-left: 5%;
      font-size: 28px;
      font-weight: 600;
    }
  }
  
  /*----- sub layout -----*/
  
  .pagenav-block {
    margin-top: 3em;
  }
  
  .neg__block {
    max-width: 960px;
    margin: -120px auto 0;
    padding: 65px 0 0;
    background-color: #fff;
  
    .inner__block {
      max-width: 810px;
    }
  }
  
  /* ttl */
  
  .h2__ttl--center {
    font-weight: 600;
    margin-bottom: 50px;
    text-align: center;
    line-height: 1.2;
  }
  
  .h2__ttl--left {
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 35px;
    padding-bottom: 20px;
  }
  
  .h3__ttl--left {
    font-weight: 600;
    margin-bottom: 25px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
  
    .small02 {
      font-size: 0.8em;
    }
  }
  
  .h3_icon01 {
    background: url(../images/common/icon01.png) no-repeat 1%;
    padding: 15px 0 12px 70px;
  }
  
  .h3_icon02 {
    background: url(../images/common/icon02.png) no-repeat 1%;
    padding: 15px 0 12px 70px;
  }
  
  .h3_icon03 {
    background: url(../images/common/icon03.png) no-repeat 1%;
    padding: 15px 0 12px 70px;
  }
  
  .h3_icon04 {
    background: url(../images/common/icon04.png) no-repeat 1%;
    padding: 15px 0 12px 70px;
  }
  
  .h3_no {
    background: #24a6e3;
    color: #fff;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    padding: 2px 10px;
    margin-right: 10px;
    text-shadow: 1px 0px 0px #1e87b9;
  }
  
  .fosm {
    font-size: 1.7rem;
  }
  
  .bar__ttl {
    font-size: 1.6rem;
    background-color: #808080;
    color: #fff;
    padding: 10px 0;
    text-align: center;
  }
  
  .sm--ttl {
    font-weight: 600;
    margin-bottom: 25px;
    padding-bottom: 5px;
  }
  
  /* font */
  
  .txt-yellow {
    color: #f8dc1f;
  }
  
  .txt-blue {
    color: #1d97d0;
  }
  
  a.txt-blue {
    color: #1d97d0;
    text-decoration: underline;
  }
  
  .txt-orenge {
    color: #f17d00;
  }
  
  .txt-red {
    color: #f00;
  }
  
  .marker {
    background: linear-gradient(transparent 60%, #edf268 60%);
  }
  
  /*　btn　*/
  
  .arw--left {
    background: url(../images/common/arw_01.png) no-repeat left center;
    padding-left: 35px;
    display: inline-block;
  }
  
  .arw--right {
    background: url(../images/common/arw_01.png) no-repeat right center;
    padding-right: 35px;
    display: inline-block;
  }
  
  .submit_btn {
    -webkit-appearance: none;
    background: url(../images/contact/button_bg.png) no-repeat left top;
    background-size: cover;
    border: none;
    line-height: 1;
    padding: 18px 110px;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #fff;
  }
  
  .r_btn {
    a {
      display: block;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0;
      text-align: center;
      border: 1px solid;
      border-radius: 30px;
      font-size: 20px;
      box-sizing: border-box;
      -webkit-transition: all .3s;
      transition: all .3s;
    }
  
    &.txt-blue a {
      border-color: #1d97d0;
      color: #1d97d0;
  
      &:hover {
        border-color: #10719f;
        color: #10719f;
      }
    }
  }
  
  a {
    &.button {
      display: block;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      border: 3px solid #1d97d0;
      padding: 18px 0 15px;
      text-align: center;
      color: #1d97d0;
      font-size: 18px;
      font-weight: 600;
      position: relative;
      box-sizing: border-box;
      -webkit-transition: all .3s;
      transition: all .3s;
  
      &:after {
        content: "";
        background: url(../images/common/arw.png) no-repeat 80% center;
        position: absolute;
        top: 38%;
        right: 10%;
        width: 12px;
        height: 16px;
        background-size: 12px 16px;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
  
      &:hover {
        background-color: #1d97d0;
        color: #fff;
  
        &:after {
          right: 7%;
          filter: alpha(opacity = 0);
          opacity: 0;
        }
      }
    }
  
    &.button02 {
      display: block;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
      border: 3px solid #1d97d0;
      background-color: #1d97d0;
      padding: 15px 0 12px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      position: relative;
      box-sizing: border-box;
  
      &:after {
        content: "";
        background: url(../images/common/arw02.png) no-repeat 80% center;
        position: absolute;
        top: 35%;
        right: 10%;
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
  
      &:hover:after {
        right: 7%;
        filter: alpha(opacity = 0);
        opacity: 0;
      }
    }
  }
  
  /* table */
  
  .def__tbl {
    width: 100%;
  
    th, td {
      padding: 25px 0;
      border-bottom: 1px solid #afafaf;
    }
  
    th {
      width: 25%;
      vertical-align: top;
      font-size: 18px;
    }
  
    td {
      font-size: 14px;
    }
  }
  
  .def__tbl02 {
    width: 100%;
    border-left: 1px solid #afafaf;
  
    th, td {
      padding: 15px 20px;
    }
  
    th {
      vertical-align: top;
      font-weight: 500;
      background-color: #9c9c9c;
      color: #fff;
      border-right: 1px solid #fff;
    }
  
    td {
      font-size: 14px;
      border-right: 1px solid #afafaf;
      border-bottom: 1px solid #afafaf;
    }
  }
  
  /* list */
  
  .disc--list li {
    list-style: disc outside;
    margin: 0 0 10px 20px;
    font-size: 0.85em;
  }
  
  #l-sub-contener {
    margin-bottom: 8em;
  
    p {
      font-size: 1.6rem;
      line-height: 170%;
    }
  }
  
  .mb_13 {
    margin-bottom: 130px;
  }
  
  .mt_13 {
    margin-top: 130px;
  }
  
  .tx_cen {
    text-align: center;
  }
  
  .p15 {
    padding: 150px 0;
  }
  
  .h4_ttl {
    border-left: 5px solid #ee9223;
    color: #ee9223;
    padding: 7px 10px;
    margin: 40px 0 0 0;
    font-size: 2rem;
    background: #fef8e6;
  }
  
  /* reason
  -------------------------*/
  
  .bg_gr {
    background: #eee;
    padding: 50px 0;
  }
  
  .reason ul.rea_btn {
    width: 1200px;
    margin: 0 auto 50px;
  }
  
  .rea_btn {
    .col-sm-4 {
      width: 31.333%;
      padding-left: 30px;
    }
  
    li {
      margin-right: 10px;
  
      a {
        font-size: 16px;
      }
  
      &:first-child a {
        color: #fff;
        background: url(../images/common/arw04.png) no-repeat 92%, #24a6e3;
        border: 2px solid #24a6e3;
      }
    }
  }
  
  .disability .rea_btn li:first-child a, .merit .rea_btn li:first-child a {
    color: #24a6e3;
    background: url(../images/common/arw.png) no-repeat 92%, #fff;
    border: 2px solid #24a6e3;
  }
  
  .disability .rea_btn li:first-child a:hover, .merit .rea_btn li:first-child a:hover {
    color: #fff;
    background: url(../images/common/arw04.png) no-repeat 92%, #24a6e3;
    border: 2px solid #24a6e3;
  }
  
  .rea_btn a {
    color: #24a6e3;
    background: url(../images/common/arw.png) no-repeat 92%, #fff;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 15px 35px;
    font-size: 1.8rem;
    border: 2px solid #24a6e3;
    transition: 0.5s;
  
    &:hover {
      color: #fff;
      background: url(../images/common/arw04.png) no-repeat 92%, #24a6e3;
      border: 2px solid #24a6e3;
    }
  }
  
  .r_list {
    background: #eeeeee;
    padding: 30px 30px 10px;
  
    li {
      background: url(../images/common/check.png) no-repeat 2%, #fff;
      font-size: 1.6rem;
      padding: 20px 20px 20px 35px;
      margin-bottom: 20px;
      box-shadow: 5px 5px 0px -2px #d6d6d6;
      -moz-box-shadow: 5px 5px 0px -2px #d6d6d6;
      -webkit-box-shadow: 5px 5px 0px -2px #d6d6d6;
    }
  }
  
  /* flow
  -------------------------*/
  
  .flow .bg_gr {
    padding: 5px 0 50px;
  }
  
  .flow_box {
    background: #fff;
    padding: 20px 30px;
    border: 6px double #eee;
    margin: 60px 0;
  }
  
  .flow {
    .box {
      border-left: 6px solid #1d97d0;
      padding: 5px 20px;
  
      p {
        margin: 0;
      }
  
      div {
        &.tel {
          font-size: 2.6rem;
          font-weight: 600;
        }
  
        em {
          font-size: 0.8em;
          font-style: normal;
        }
      }
    }
  
    a.button {
      margin: 2em auto 1em;
  
      &:link {
        margin: 2em auto 1em;
      }
    }
  }
  
  /* merit
  -------------------------*/
  
  .merit_box {
    color: #24a6e3;
    border: 6px double #24a6e3;
    padding: 15px 25px;
  
    p {
      font-size: 1.9rem !important;
    }
  }
  
  .merit .d_table {
    background: #eee;
    padding: 30px;
    font-size: 1.8rem;
  
    table {
      width: 100%;
    }
  
    thead th {
      background: #959595;
      color: #fff;
      text-align: center;
      border: 2px solid #eee;
      padding: 15px 0;
    }
  
    tbody {
      th, td {
        background: #fff;
        text-align: center;
        border: 2px solid #eee;
        padding: 15px 0;
      }
    }
  }
  
  /* disability
  -------------------------*/
  
  .disability {
    .d_table {
      background: #eee;
      padding: 30px;
      font-size: 1.8rem;
      margin: 30px 0;
    }
  
    .ta_ttl {
      background: #1386d7;
      text-align: center;
      border: 2px solid #eee;
      border-bottom: none;
      padding: 15px 0;
      color: #fff;
      font-size: 1.9rem;
    }
  
    .d_table {
      table {
        width: 100%;
      }
  
      thead th {
        background: #959595;
        color: #fff;
        border: 2px solid #eee;
        padding: 15px;
      }
  
      tbody {
        th, td {
          background: #fff;
          border: 2px solid #eee;
          padding: 10px 15px;
        }
  
        th {
          font-weight: normal;
        }
      }
    }
  
    .d_table02 {
      background: #eee;
      padding: 8px;
      font-size: 1.7rem;
      margin: 30px 0;
  
      .ta_ttl {
        background: #5e5e5e;
        text-align: center;
        border: 2px solid #eee;
        border-bottom: none;
        padding: 10px 0;
        color: #fff;
        font-size: 1.9rem;
      }
  
      table {
        width: 100%;
      }
  
      thead th {
        background: #959595;
        color: #fff;
        border: 2px solid #eee;
        padding: 15px;
      }
  
      tbody {
        th, td {
          background: #fff;
          border: 2px solid #eee;
          padding: 10px 15px;
        }
  
        th {
          font-weight: normal;
          width: 120px;
        }
      }
    }
  }
  
  .insp {
    background: #eee;
    padding: 20px;
  
    dl {
      background: #fff;
      padding: 15px 20px 20px;
      box-shadow: 5px 5px 0px -2px #d6d6d6;
      -moz-box-shadow: 5px 5px 0px -2px #d6d6d6;
      -webkit-box-shadow: 5px 5px 0px -2px #d6d6d6;
    }
  
    dt {
      background: url(../images/common/check.png) no-repeat left center;
      color: #ee9223;
      font-size: 1.8rem;
      padding: 5px 0 5px 24px;
      border-bottom: 1px dashed #959595;
      margin-bottom: 10px;
    }
  }
  
  .r_list02 {
    background: #eeeeee;
    padding: 30px 30px 10px;
  
    li {
      background: #fff;
      font-size: 1.6rem;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: 5px 5px 0px -2px #d6d6d6;
      -moz-box-shadow: 5px 5px 0px -2px #d6d6d6;
      -webkit-box-shadow: 5px 5px 0px -2px #d6d6d6;
  
      &:first-letter {
        color: #fff;
        font-weight: bold;
        font-size: 1.8rem;
        background: #f17d00;
        border-radius: 18px;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        padding: 2px 8px;
        margin-right: 10px;
  
        /*text-shadow:1px 0px 0px #1e87b9;*/
      }
    }
  }
  
  /* money
  -------------------------*/
  
  .money {
    .ta_ttl {
      background: #1386d7;
      text-align: center;
      border: 2px solid #eee;
      border-bottom: none;
      padding: 15px 0;
      color: #fff;
      font-size: 1.9rem;
    }
  
    .d_table {
      background: #eee;
      padding: 30px;
      font-size: 1.7rem;
  
      table {
        width: 100%;
      }
  
      th {
        background: #959595;
        color: #fff;
        text-align: left;
        border: 2px solid #eee;
        padding: 10px;
        width: 180px;
      }
  
      tbody td {
        background: #fff;
        text-align: left;
        border: 2px solid #eee;
        padding: 10px;
      }
    }
  }
  
  /* case
  -------------------------*/
  
  .case_detail h2 {
    font-size: 2.6rem;
    border: 1px solid #1386d7;
    color: #1386d7;
    padding: 30px 0;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    margin: 80px 0;
  }
  
  .case .d_table {
    background: #eee;
    padding: 10px;
    font-size: 1.6rem;
  
    table {
      width: 100%;
    }
  
    thead th {
      background: #959595;
      color: #fff;
      text-align: center;
      border: 2px solid #eee;
      padding: 15px;
    }
  
    tbody {
      th, td {
        background: #fff;
        text-align: left;
        border: 2px solid #eee;
        padding: 15px;
      }
  
      th {
        width: 120px;
      }
    }
  
    a {
      color: #1386d7;
  
      &:hover {
        color: #2ea1f1;
      }
    }
  
    tbody tr td:nth-child(2) {
      width: 700px;
    }
  }
  
  /* faq
  -------------------------*/
  
  .faq {
    dt {
      font-size: 1.8rem;
      background: #c5ecff;
      color: #1d97d0;
      padding: 12px 10px;
    }
  
    dd {
      font-size: 1.6rem;
      line-height: 180%;
      padding: 12px 10px;
      margin-bottom: 30px;
    }
  }
  
  /* cost
  -------------------------*/
  
  .cost {
    table {
      width: 100%;
      font-size: 1.5rem;
    }
  
    thead th {
      background: #1386d7;
      color: #fff;
      text-align: center;
      border: 2px solid #eee;
      padding: 15px;
    }
  
    tbody {
      th, td {
        background: #fff;
        text-align: left;
        border: 2px solid #eee;
        padding: 15px;
      }
  
      th {
        background: #959595;
        color: #fff;
      }
    }
  }
  
  /* voice */
  
  .voice .voice--list {
    overflow: hidden;
  
    li {
      float: left;
      width: 29.333%;
      margin-top: 2%;
      border: 1px solid #ddd;
  
      &:nth-child(3n+2) {
        margin: 2% 2% 0;
      }
  
      figure {
        position: relative;
        width: 100%;
        max-height: 340px;
        overflow: hidden;
  
        img {
          width: 100%;
        }
      }
    }
  }
  
  /* topics
  -------------------------*/
  
  .topic {
    .top--topics {
      background: #eee;
      padding: 120px 0 80px;
    }
  
    dl {
      display: table;
      width: 100%;
      border-bottom: 1px dashed #ddd;
      padding-bottom: 20px;
      margin-bottom: 25px;
  
      dt {
        padding: 0 10px;
      }
    }
  }
  
  .top--topics dl dd {
    padding: 0 10px;
  }
  
  .topic {
    dl {
      dt {
        display: table-cell;
        width: 30%;
        font-weight: 500;
  
        span {
          display: inline-block;
          width: 140px;
          padding: 4px 0;
          margin-right: 20px;
          color: #fff;
          text-align: center;
        }
  
        time {
          display: inline-block;
        }
      }
  
      dd a {
        color: #444;
        text-decoration: underline;
      }
    }
  
    .rea_btn {
      margin-bottom: 3em;
  
      .col-sm-3 {
        padding: 5px 10px;
      }
  
      a {
        background: #fff;
        text-align: center;
      }
    }
  }
  
  .rea_btn a {
    &.news {
      color: #24a6e3;
      border-color: #24a6e3;
  
      &:hover {
        background-color: #24a6e3;
      }
    }
  
    &.consultation {
      color: #fe7e00;
      border-color: #fe7e00;
  
      &:hover {
        background-color: #fe7e00;
      }
    }
  
    &.important {
      color: #fe1800;
      border-color: #fe1800;
  
      &:hover {
        background-color: #fe1800;
      }
    }
  
    &.seminar {
      color: #4a7b06;
      border-color: #4a7b06;
  
      &:hover {
        background-color: #4a7b06;
      }
    }
  }
  
  .topic .rea_btn a:hover {
    color: #fff;
  }
  
  /* topic_detail */
  
  .topic_detail {
    .t_item {
      background: #888888;
      color: #fff;
      padding: 2px 20px;
      margin-right: 10px;
    }
  
    h2 {
      font-size: 2.9rem;
      padding: 20px 0;
      border-bottom: 1px dashed #dddddd;
    }
  }
  
  .editor-block {
    margin-bottom: 8em;
  }
  
  /* contact */
  
  .contact label, .confirm label {
    font-size: 1.1em;
    margin-bottom: 8px;
  }
  
  .contact {
    .form-group {
      margin-bottom: 1.5em;
      width: 100%;
    }
  
    .btn {
      padding: 8px 40px;
      margin: 40px 10px 10px;
    }
  }
  
  .confirm .btn {
    padding: 8px 40px;
    margin: 40px 10px 10px;
  }
  
  .contact .req {
    margin-left: 10px;
    font-size: 0.8em;
    font-weight: 400;
    color: firebrick;
  }
  
  .confirm {
    .form-group {
      width: 100%;
      display: table;
      margin-bottom: 0;
  
      &.end {
        border-bottom: 1px solid #ccc;
      }
    }
  
    .form-ttl {
      width: 26%;
      padding: 2%;
      vertical-align: middle;
      display: table-cell;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  
    .form-txt {
      width: 76%;
      padding: 2%;
      display: table-cell;
      border-top: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  
    .req {
      display: none;
    }
  }
  
  .wm {
    width: 40% !important;
    display: inline-block;
  }
  
  .ws {
    width: 25% !important;
    display: inline-block;
  }
  
  /* sitemap */
  
  .sitemap a {
    color: #333;
  }
  
  .catetag {
    background: #ececec;
    border-left: 8px solid #1d97d0;
    padding: 1.5%;
    font-size: 1.4em;
    margin: 3em auto 0.5em;
  
    a:hover {
      color: #1d97d0;
    }
  }
  
  ul {
    &.sitemap--list {
      padding-left: 1%;
  
      li {
        margin: 1.5% 1% 0 0;
        line-height: 1.4;
        width: 32%;
        display: inline-block;
        vertical-align: top;
  
        &:nth-child(3n+3) {
          margin-right: 0;
        }
  
        a {
          display: block;
          background: url(../images/common/arw05.png) no-repeat 4% 50%;
          padding: 6% 4% 6% 12%;
          border: 2px solid #ddd;
          font-size: 1.1em;
          box-sizing: border-box;
          -webkit-transition: all .3s;
          transition: all .3s;
  
          &:hover {
            background: #24a6e3 url(../images/common/arw.png) no-repeat 7% 50%;
            color: #fff;
            border-color: #24a6e3;
          }
        }
      }
    }
  
    /* privacy */
    &.privacy--list {
      padding: 0 25px;
  
      li {
        list-style: decimal outside;
        padding: 30px 0;
        border-top: 1px dashed #ddd;
        font-weight: 400;
        font-size: 1.25em;
      }
    }
  }


/* line_yoyaku */
.ol-list-01{
  counter-reset:ol_li;
  li{
    position:relative;
    padding-left:35px;
    font-size: 1.6rem;

    &::before{
      counter-increment:ol_li;
      content:counter(ol_li,decimal-leading-zero) ".";
      color:#1d97d0;
      font-weight: 600;
      position:absolute;
      top:0;
      left:0;
    }
    &:not(:last-child){
      margin-bottom:5px;
    }
  }
}

.ul-list-01{
  li{
    position:relative;
    padding-left:20px;
    font-size: 1.6rem;
    
    &::before{
      content:'〇';
      position:absolute;
      top:0;
      left:0;
    }
    &:not(:last-child){
      margin-bottom:10px;
    }
  }
}

.line__wrap{
  line-height: 1.8;
  &-flow{
    background-color: #FAFAFA;
    padding: 40px;
    line-height: 1.8;
    border: 3px solid #01B901;
    &--ttl{
      margin: 0 0 40px;
      padding: 6px;
      font-size: 22px;
      background: #01B901;
      color: #fff;
      text-align: center;
    }
    &--link{
      li{
        &:not(:last-child){
          margin-right: 5%;
        }
      }
    }
    &--attention{
      background-color: rgba(255,255,255,0.8);
      padding: 40px 30px 30px;
      border: 1px solid #01B901;
      line-height: 1.6;
      position: relative;
      &---ttl{
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #fff;
        background-color: #FF0000;
        padding: 4px 15px;
        position: absolute;
        top: -15px;
        left: 30px;
      }
    }
    &--cost{
      background-color: rgba(255,255,255,0.8);
      padding: 40px 30px 30px;
      border: 1px solid #01B901;
      line-height: 1.6;
      position: relative;
      &---ttl{
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #fff;
        background-color: #FF0000;
        padding: 4px 15px;
        position: absolute;
        top: -15px;
        left: 30px;
      }
      &---contents{
        .items{
          &:not(:last-child){
            margin-bottom: 30px;
          }
          dt{
            width: 180px;
            span{
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
              color: #333;
              border: 1px solid #333;
              padding: 4px 15px;
              border-radius: 5px;
            }
          }
          dd{
            width: calc(100% - 180px);
            p{
              margin-top: 0;
            }
            .title{
              font-size: 16px;
              font-weight: 500;
              margin: 0 0 10px;
            }
            .list{
              margin-left: 24px;
              li{
                position: relative;
                padding-left: 20px;
                &:not(:last-child){
                  margin-bottom: 10px;
                }
                &::before{
                  content: '⇒';
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
          }

          //other
          &.kojin{
            dt{
              span{
                color: #4B7C07;
                border-color: #4B7C07;
              }
            }
            dd{
              .title{
                color: #4B7C07;
              }
            }
          }
          &.houjin{
            dt{
              span{
                color: #FF7E00;
                border-color: #FF7E00;
              }
            }
            dd{
              .title{
                color: #FF7E00;
              }
            }
          }
        }
      }
    }
  }
}
